import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import { googleAnalytic } from "../components/googleAnalytics.js"
import {
  MDBBadge,
  MDBBtn,
  MDBChip,
  MDBDatatable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBTextArea,
} from "mdb-react-ui-kit";
import ReactGA from 'react-ga';
import { browserName, browserVersion } from "react-device-detect";
import { useLocation, useNavigate } from "react-router";
import Popup from "../components/Popup";
import CreateMember from "../components/createMember";
import LogATime from "../components/logatime";
import SubPopup from "../components/supPopup";
import { activateMemberShip, getMemberTransactions, logEvent, membersessions, suspendMemberShip, getWhatsAppHistory, updateMemberDNDvalue } from "../services/apiServices";
import Payments from "./Payments";
import ReceiptPrint from "./ReicptPrint";
import EditMemberShip from "../components/editMembership";
import PageError from "./PageError";
import TransactionDetails from "../components/TransactionDetails";

export default function MDetails(props) {
  const [centredModal, setCentredModal] = useState(false);
  const toggleOpen = () => setCentredModal(!centredModal);
  const [mPData, setMPData] = useState({
    columns: ["S.no", "Plan Name", "Validity", "Expires On"],
    rows: [],
  });
  const [logtimeShow, setLogtimeShow] = useState(false);
  const [selData, setSelData] = useState(false);
  const [value, setvalue] = useState("");
  const [apiError, setApiError] = useState(false);
  const [EditMembPopup, setEditMembPopup] = useState()
  const [valuee, setValuee] = useState(-1);
  const [editTrans, seteditTrans] = useState();
  const [flag, setFlag] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isTransOpen, setIsTransOpen] = useState(false);
  const [mId, setMId] = useState(null);
  const [basicModal3, setBasicModal3] = useState(false);
  const toggleOpen3 = () => setBasicModal3(!basicModal3);

  const [mAData, setMAData] = useState({
    columns: [
      "S.no",
      "Addon Name",
      "Transaction Date",
      "Start Date",
      "End Date",
      "Status",
      "Edit",
    ],
    rows: [],
  });
  const [mPMData, setPMData] = useState({
    columns: [
      "S.no",
      "Token",
      "Transaction ID",
      "Date",
      "Payment Mode",
      "Total Amount",
      "Paid",
      "Balance",
    ],
    rows: [],
  });
  const [mATData, setMATData] = useState({
    columns: ["S.no", "Date", "Check-In Time", "Check-Out Time"],
    rows: [],
  });
  const [expiresin, setExpiresin] = useState(new Date().toLocaleDateString());
  const [allData, setALLData] = useState([]);
  const [transDate, setTransDate] = useState(new Date())
  const [basicActive, setBasicActive] = useState("tab1");
  const [DNDToggle, setDNDToggle] = useState(false)

  function handleTransdate(date) {
    console.log("in transdetails handleTransDate", date);
    setTransDate(date)
  }
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  function createMemberCallback(e, updates, newMember) {
    togglePopup();
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  async function activateFunction(e) {
    e.preventDefault();
    let id = location.state._id;
    await activateMemberShip(id);
    let ip = localStorage.getItem("ipaddress");
    let browserinfo = browserName + "-" + browserVersion + ".0.0";
    let Eventtype = "Plan";
    let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
    let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
    let flag = "Plan Activation Successfull";
    await googleAnalytic("MDetails", 'Member', 'Activation Member Button', 'Member Activated')

    // ReactGA.event({
    //   category: 'Member',
    //   action: 'Activation Member Button',
    //   label: 'Member Activated',
    // });

    await logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);


    window.location.reload();
  }

  function openEditForm(e) {
    e.stopPropagation();
    setMId((allData.length > 0 && allData[0]._id) ? allData[0]._id : -1)
    togglePopup();
  }
  function onsubmit() {
    setFlag(1);
  }
  console.log(EditMembPopup);
  function updatePopupFunction(e, data) {
    e.preventDefault();
    console.log(data);
    seteditTrans(data);
  }


  const navigate = useNavigate();
  const location = useLocation();

  const [openPayment, setOpenPayment] = useState(false);
  const [openReceipt, setOpenReceipt] = useState(false);
  const [wtsappHistory, setWtsappHistory] = useState([])
  const [tId, setTId] = useState(null);
  const [transid, settransid] = useState("")
  const [Rid, setRid] = useState(null);

  const toggleTransPopup = (event, tId, _id, id) => {
    event.preventDefault();
    setTId(tId);
    setIsTransOpen(!isTransOpen);
    setRid(id);
    settransid(_id)
  };
  const closeTransPopup = () => {
    if (isTransOpen) {
      setIsTransOpen(false);
    }
  };

  async function fetchData() {
    setFlag(0);
    console.log(location);
    setDNDToggle(location.state.isDND)
    const response = await getMemberTransactions(location.state._id);
    if (!response) {
      return;
    }
    if (response === "false") {
      setApiError(true)
      return;
    }
    const membersData = await response.json();
    console.log(membersData);
    setALLData(membersData);


    const resp = await membersessions(location.state._id);

    if (!resp) {

      return;
    }
    if (resp === "false") {
      setApiError(true)
      return;
    }
    const sessionData = await resp.json();
    console.log(sessionData);


    const res = await getWhatsAppHistory(location.state._id);

    if (!res) {

      return;
    }
    if (res === "false") {
      setApiError(true)
      return;
    }
    const whatsappHistory = await res.json();
    console.log(whatsappHistory, '999999999000000000000000000000');
    setWtsappHistory(whatsappHistory)

    const data = membersData[0]
    location.state.fname = data.fname
    location.state.lname = data.lname
    location.state.email = data.email
    location.state.number = data.number
    location.state.sex = data.sex
    location.state.height = data.height
    location.state.bloodgroup = data.bloodgroup
    location.state.isDND = data.isDND
    setDNDToggle(data.isDND)
    console.log(membersData);
    // localStorage.setItem("memberm_filter", JSON.stringify(location.state.filters));
    if (location.state.return === "mmanager") {
      localStorage.setItem("memberm_filter", JSON.stringify({
        filters: location.state.filters,
        dashboardFID: location.state.anotherColumn,
        dashboardFfdate: location.state.Date11,
        dashboardFtdate: location.state.Date22,
      }));
    }

    if (!membersData) {

      navigate("/");
      return;
    }




    let ship_data = {
      columns: [
        "S.no",
        "Plan Name",
        "Transaction Date",
        "Start Date",
        "End Date",
        "Session(s)",
        "Balance Session(s)",
        "Status",
        "Actions"
      ],
      rows: [],
    };
    let index_m = 0;
    console.log(membersData);
    membersData[0].trans.forEach((data, index) => {
      let PauseObj = (data.pause && data.pause.length > 0) ? data.pause.sort((a, b) => b.sno - a.sno)[0] : {};
      let filterExtend = data.extend && data.extend.length > 0 ? data.extend.filter((obj) => {
        let oldEndDate = new Date(obj.oldEndDate);
        let newEndDate = new Date(obj.newEndDate);
        let currentDate = new Date();
        return oldEndDate <= currentDate && currentDate <= newEndDate;
      }) : [];

      if (data.isplan === "1") {
        index_m++;
        const session = sessionData.length > 0 && sessionData.filter(item => item.transid === data._id)
        // const sessionstatus=session[0].balsessions==0
        // const plandata = membersData[0].plan.filter((item) => item._id == data.itemid).length > 0 ? (membersData[0].plan.filter((item) => item._id == data.itemid)[0]) : []
        // console.log(plandata);
        let rarray = [];
        rarray.push(index_m);
        rarray.push(
          membersData[0].plan.filter((item) => item._id === data.itemid).length > 0 ? (membersData[0].plan.filter((item) => item._id === data.itemid)[0].name) : ""
        );
        rarray.push(
          new Date(data.createdAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })
        );
        rarray.push(
          new Date(data.startdate).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })
        );
        rarray.push(
          new Date(data.expirydate).toLocaleString("en-IN", {
          })
        );
        rarray.push(
          session && session.length > 0 ? session[0].totalsessions : "--"
        );
        rarray.push(
          session && session.length > 0 ? session[0].balsessions : "--"
        );
        rarray.push(<div className="title">{
          (PauseObj && PauseObj.resume) ? "paused" :
            (filterExtend.length > 0 ? "extend" :
              ((session && session.length > 0 && session[0].balsessions <= 0) || (new Date(data.expirydate) < new Date()) ? "Inactive" : "Active"))}
        </div>);
        rarray.push(
          (session && session.length > 0 && session[0].balsessions < 0 ? <MDBIcon fas icon="edit" /> : <MDBIcon fas icon="edit" onClick={(e) => { !membersData[0].issuspend && updatePopupFunction(e, data) }} />)
        );
        ship_data.rows.push(rarray);
      }
    });
    setMPData(ship_data);
    let addons = [];
    let payments = [];

    let totaldue = 0;
    let ptransArr =
      membersData[0].trans.length > 0
        ? membersData[0].trans
        : [];
    console.log(ptransArr);
    const filteredTrans = membersData[0].trans.reduce((acc, current) => {
      // Check if current transaction with the same tId already exists in accumulator
      const existingTrans = acc.find(trans => trans.tId === current.tId);
      if (!existingTrans) {
        // If not, add it to the accumulator
        acc.push(current);
      }
      return acc;
    }, []);
    console.log(filteredTrans);

    ptransArr = ptransArr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    // ptransArr.map((data, index) => {
    filteredTrans.forEach((data, index) => {
      console.log(membersData, membersData[0].addons, data, data.itemid, '99999999999');

      let membership = membersData[0].plan.filter((item) => item._id == data.itemid)
      if (membership.length === 0) {
        membership = membersData[0].addons.filter((item) => item._id == data.itemid)
      }
      console.log(membership);

      let rarray = [];
      rarray.push(index + 1);
      // rarray.push(data.token)
      rarray.push(<button className="button-link link-color" onClick={(event) => toggleTransPopup(event, data.tId, data._id)}>{data.token ? data.token : data.tId}</button>)

      rarray.push(data.tId);
      rarray.push(
        new Date(data.createdAt).toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
        })
      );
      rarray.push(
        data.payment.mode === 1 ? (
          "Cash"
        ) : data.payment.mode === 2 ? (
          "Card"
        ) : data.payment.mode === 3 ? (
          "UPI"
        ) : data.payment.mode === 4 ? (
          "Wallet"
        ) : data.payment.mode === 5 ? (
          "Coupon"
        ) : data.payment.mode === -2 ? (
          "Payment Link"
        ) : data.payment.mode === 6 ? (
          <>
            Multiple <br /> Cash:
            {JSON.parse(data.payment.payinfo).info.cash_amt}
            <br /> Credit Card:{JSON.parse(data.payment.payinfo).info.cc_amt}
            <br /> UPI:
            {JSON.parse(data.payment.payinfo).info.upi_amt +
              "(" +
              JSON.parse(data.payment.payinfo).info.upi_input +
              ")"}
            <br />
            Wallet:{" "}
            {JSON.parse(data.payment.payinfo).info.wallet_amt +
              "(" +
              JSON.parse(data.payment.payinfo).info.wallet_input +
              ")"}
          </>
        ) : data.payment.mode >= 7 ? <>

          {JSON.parse(data.payment.payinfo).info.userpaymethod}
        </> : "Account Payment Type"

      );
      rarray.push(data.payment.totalamount ? "₹" + data.payment.totalamount : "--");
      rarray.push(data.payment.paid ? "₹" + data.payment.paid : "--");
      rarray.push(data.payment.balance !== 0 ?
        <div className="d-flex align-items-center justify-content-end">
          <div className="me-2">
            {data.payment.balance ? "₹" + data.payment.balance : "--"}
          </div>
          <div className="link-color" onClick={(e) => payNow(e, data, membership)}>Pay Now</div>
        </div>
        : (data.payment.balance ? "₹" + data.payment.balance : "--"));
      if (!data.members) {
        totaldue = totaldue - data.payment.paid * 1;
      } else {
        totaldue = totaldue + data.payment.balance * 1;
      }

      payments.push(rarray);
    });
    let index_a = 0;
    // setTotalDue(totaldue);
    membersData[0].trans.forEach((data, index) => {
      if (data.isplan !== "1") {
        index_a++;
        let PauseObj = (data.pause && data.pause.length > 0) ? data.pause.sort((a, b) => b.sno - a.sno)[0] : {};
        let filterExtend = data.extend && data.extend.length > 0 ? data.extend.filter((obj) => {
          let oldEndDate = new Date(obj.oldEndDate);
          let newEndDate = new Date(obj.newEndDate);
          let currentDate = new Date();
          return oldEndDate <= currentDate && currentDate <= newEndDate;
        }) : [];
        let rarray = [];
        rarray.push(index_a);
        rarray.push(membersData[0].addons.length > 0
          && membersData[0].addons.filter((item) => item._id === data.itemid).length > 0
          && membersData[0].addons.filter((item) => item._id === data.itemid)[0].name);
        rarray.push(
          new Date(data.createdAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })
        );
        rarray.push(
          new Date(data.startdate).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })
        );
        rarray.push(
          new Date(data.expirydate).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })
        );
        rarray.push(<div className="title">{
          (PauseObj && PauseObj.resume) ? "paused" :
            (filterExtend.length > 0 ? "extend" :
              (new Date(data.expirydate) > new Date() ? "Active" : "Inactive"))}
        </div>);
        rarray.push(
          <MDBIcon fas icon="edit" onClick={(e) => !membersData[0].issuspend && updatePopupFunction(e, data)} />
        );

        addons.push(rarray);
      }
    });
    // mAData.rows = addons;
    setMAData((prevData) => ({
      ...prevData,
      rows: addons,
    }));
    // mPMData.rows = payments;
    setPMData((prevData) => ({
      ...prevData,
      rows: payments,
    }));
    setExpiresin(getExpiryDate(membersData[0]));
    setValuee(location && location.state && location.state.anotherColumn);

  }

  // mATData.rows = attendance;


  useEffect(() => {
    let attendance = [];
    allData.length > 0 && allData[0].attendance.length > 0 && allData[0].attendance.forEach((data, index) => {
      let rarray = [];
      rarray.push(index + 1);
      rarray.push(getDateTime(data.intime).date);
      rarray.push(
        getDateTime(data.intime).time === "NaN:NaN"
          ? " -- "
          : getDateTime(data.intime).time
      );
      rarray.push(
        getDateTime(data.outtime).time === "NaN:NaN"
          ? " -- "
          : getDateTime(data.outtime).time
      );
      attendance.push(rarray);
    });
    setMATData((prevData) => ({
      ...prevData,
      rows: attendance,
    }));
  }, [allData])
  let membership = {};
  if (allData.length > 0 && allData[0].planid) {
    const { plan, addons } = allData[0];

    if (plan && plan.length > 0) {
      membership = plan.find(item => item._id === allData[0].planid) || {};
    }

    if (addons && addons.length > 0 && !membership._id) {
      membership = addons.find(item => item._id === allData[0].planid) || {};
      if (membership._id) {
        membership.membershiptype = 'addon';
      }
    }
  }
  console.log(membership)
  function getDateTime(tdbtime) {
    var now = new Date(tdbtime);
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    if (month.toString().length === 1) {
      month = "0" + month;
    }
    if (day.toString().length === 1) {
      day = "0" + day;
    }
    if (hour.toString().length === 1) {
      hour = "0" + hour;
    }
    if (minute.toString().length === 1) {
      minute = "0" + minute;
    }
    if (second.toString().length === 1) {
      second = "0" + second;
    }
    var dateTime = {
      date: day + "-" + month + "-" + year,
      time: hour + ":" + minute,
    };
    return dateTime;
  }

  function getExpiryDate(data) {
    try {
      console.log(data);

      let startdate = new Date();

      let enddate = data.expirydate;
      console.log(startdate);
      console.log(enddate);
      let expirydate = new Date(enddate).getTime() - startdate.getTime();
      let cd = 24 * 60 * 60 * 1000;
      console.log(Math.floor(expirydate / cd));
      let exdays = Math.floor(expirydate / cd) + 1;
      return exdays >= 1
        ? exdays + " Day(s)"
        : data.attendance[0] &&
          new Date(data.attendance[0].intime).getTime() >=
          new Date(enddate).getTime()
          ? "Expired (Overdue)"
          : "Expired";
    } catch (e) {
      console.log("Error :::::::::::::::: " + e);
    }
  }
  function gotohome() {
    cancelThis();
  }

  function cancelThis(event) {
    clearAll();
  }

  function clearAll() {
    setOpenPayment(false);
    setOpenReceipt(false);
  }
  function oncancelpayment() {
    setOpenPayment(false);
    setOpenReceipt(false);
  }

  function preaddUser(dataObj) {
    localStorage.setItem("preadd_plan", JSON.stringify(dataObj));
    localStorage.setItem("preadd_member", JSON.stringify(dataObj));
    navigate("/home");
  }
  function onPayemntDone(event) {
    setOpenReceipt(true);
  }
  function payNow(e, data, plan) {
    e.preventDefault();
    console.log(data, plan);

    let dueTrans = data
    let balanceamt = data.payment.balance;
    let totalPrice = dueTrans ? dueTrans.payment.totalamount : 0;
    localStorage.setItem(
      "allData",
      JSON.stringify({
        price: balanceamt,
        isplan: 3,
        ref_tId: dueTrans.tId,
        token: data.token,
        totalPrice: totalPrice,
      })
    );
    let planDetails = plan
    const taxIncluded = planDetails && planDetails.length > 0 && planDetails[0].taxcheck === true ? "Included" : "Excluded"
    const taxamounts = dueTrans.payment.taxamounts ? dueTrans.payment.taxamounts : [{ TaxCheck: `${taxIncluded}` }];
    localStorage.setItem("transactionId", dueTrans.tId);
    localStorage.setItem(
      "selItems",
      JSON.stringify([{
        Item: planDetails[0],
        idate: dueTrans.startdate,
        isplan: 3,
        members: [dueTrans.members],
        quantity: 1,
        taxamounts: taxamounts,
        _id: dueTrans.itemid,
        token: data.token,
        ref_tId: dueTrans.tId,
        price: balanceamt,
      }])
    );
    setOpenPayment(true);
  }
  useEffect(() => {
    if (flag === 1) {
      fetchData();
    }
  }, [flag]);

  function showInfo(event, page, tInfo) {
    event.preventDefault();
    console.log(page, '999999999999');

    navigate("/" + page, {
    });
  }
  function openLogTime(e, data, value) {
    console.log(data)
    setSelData(data);
    setvalue(value);
    setLogtimeShow(true);
  }
  function closeLogTime() {
    setLogtimeShow(false);
  }
  function checkMember(updates, member) {
    window.location.reload();
  }
  console.log(allData);
  const [reason, setReason] = useState("")
  const [errors, setErrors] = useState("");

  async function suspendSubmitFunction(e) {
    e.preventDefault();
    let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")) ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
    let ip = localStorage.getItem("ipaddress");
    let browserinfo = browserName + "-" + browserVersion + ".0.0";
    let Eventtype = "Membership";
    let username1 = JSON.parse(localStorage.getItem("loggedUserInfo")) ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : ""; if (reason !== "") {
      let id = location.state._id;
      await suspendMemberShip(id);
      await googleAnalytic("MDetails", 'Member', 'Suspend Member Button', 'Member Suspended')
      // ReactGA.event({
      //   category: 'Member',
      //   action: 'Suspend Member Button',
      //   label: 'Member Suspended',
      // });
      let flag = `Member Suspend Successfull (${location.state.fname} ${location.state.lname})`;
      await logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
      window.location.reload();
    } else { setErrors("Field can't be empty.") }
  }
  const handlerReason = (e) => {
    setReason(e.target.value)
  }
  const handleReload = () => {
    window.location.reload();
  }

  const [basicData, setBasicData] = useState({
    columns: [],
    rows: [],
  });


  /**
* mappingTemplate function is used for map the varibles .
* created by vamshi
* @function
* 
* @description
* This mappingTemplate function has been create on [16-7-2024] used to map the template varibles with the application values.
*/
  const mappingTemplate = (params, template) => {
    let result = template;
    params.forEach(param => {
      if (param.name && param.value) {
        const placeholder = `{{${param.name}}}`;
        const boldPlaceholder = `*${placeholder}*`;
        if (result.includes(boldPlaceholder)) {
          result = result.replace(boldPlaceholder, `<b>${param.value}</b>`);
        } else {
          result = result.replace(placeholder, param.value);
        }
      }
    });
    return result;
  };

  useEffect(() => {
    const generateRow = (item, snumber) => {
      const memberprams = item.templatevalues.filter(data => data.memberid == location.state._id);
      let message = item.template;

      if (memberprams.length > 0) {
        message = mappingTemplate(memberprams[0].customParams, item.template);
      }

      return [
        snumber,
        item.campainname,
        item.updatedAt ? new Date(item.updatedAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }) : "NA",
        item.wtscategory !== "campaign" ? "Notification" : "Campaign",
        item.templatename,
        <div className='m-0 ms-2' dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br />') }}></div>
      ];
    };

    const popupRow = wtsappHistory?.length > 0
      ? wtsappHistory.map((item, index) => generateRow(item, index + 1))
      : [];

    setBasicData({
      columns: ["S.no", 'Campain Name', 'Date', "category", "templatename", 'Message'],
      rows: popupRow
    });
  }, [wtsappHistory]);


  /**
* handleDNDToggle function is used to handle the DND toggle .
* created by vamshi
* @function
* 
* @description
* This handleDNDToggle function has been create on [17-7-2024] used set the DND toggle state.
*/
  const handleDNDToggle = async (e) => {
    e.preventDefault()
    const update = {
      isDND: !DNDToggle,
      id: location.state._id
    }
    await updateMemberDNDvalue(location.state._id, update);
    setDNDToggle(!DNDToggle)
  }
  return (
    <>
      {apiError ? <PageError handleReload={handleReload} /> : <>
        <LeftPane isactive="150" />
        <Header />

        <div className="gym-main gym-main-div rightContent">
          <div className="d-flex align-items-center justify-content-between w-100 flex-mob-col">
            <div className="d-flex align-items-center flex-mob-wrap justify-content-between w-100">
              <div className="d-flex align-items-center w-auto white-nowrap">
                <MDBIcon fas icon="arrow-left" className="fs-3" onClick={(e) => showInfo(e, location.state.return, { data: valuee })} />
                <h4 className="m-2 ms-4 title">
                  {location.state.fname} {location.state.lname}
                </h4>
              </div>
              <div className="d-flex align-items-center justify-content-between w-100">


                {(allData && allData.length > 0) && !allData[0].issuspend ?
                  <>
                    <div className="d-flex align-items-start">
                      <div className="ms-3">
                        <MDBBtn size='sm' className="btn btn-sm btn-secondary px-3" onClick={(e) => {
                          openEditForm(e)
                          setEditMembPopup((allData && allData.length > 0) ? allData[0] : location.state)
                        }}>
                          <MDBIcon far icon="edit" />
                        </MDBBtn>
                      </div>
                      {allData.length > 0 && allData[0].trans.length > 0 && <><div className="ms-2">
                        <MDBBtn size='sm' className={allData.length > 0 && allData[0] ? (allData[0].checkinflag === 1 ? "btn btn-danger btn-sm me-2" : "btn btn-primary btn-sm me-2") :
                          (location.state && location.state.checkinflag === 1 ? "btn btn-danger" : "btn btn-primary")}
                          onClick={(e) => openLogTime(e, allData[0], allData[0].checkinflag === 1 ? "Check-Out" : "Check-In")}
                        >{allData.length > 0 && allData[0].checkinflag && allData[0].checkinflag === 1 ? "Check-Out" : "Check-In"}</MDBBtn>
                      </div>
                        <button class="btn btn-danger btn-small ms-2" onClick={toggleOpen3}>suspend</button></>}
                    </div>
                  </>
                  : <>
                    <MDBBadge pill className='ms-4' color='danger' light>
                      Member Suspended
                    </MDBBadge>
                    <MDBBtn className="ms-2" onClick={toggleOpen}>Activate</MDBBtn>
                  </>}

                <div className="d-flex align-items-center">
                  <label className="mb-0 switchlabel">DND: </label>
                  <label className="switch">
                    <input className="form-control" type="checkbox" checked={DNDToggle} onChange={(e) => handleDNDToggle(e)} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="usedetails">
            <div className="usedetails-data" >
              <MDBChip>
                <span className="me-1">Member Id: </span><b>{location.state.mid ? location.state.mid : "--"}</b>
              </MDBChip>
            </div>
            <div className="usedetails-data" >
              <MDBChip>
                <span className="me-1">Contact: </span><b>{location.state.number ? location.state.number : "--"}</b>
              </MDBChip>
            </div>
            <div className="usedetails-data" >
              <MDBChip>
                <span className="me-1">Gender: </span><b>{location.state.sex ? location.state.sex : "--"}</b>
              </MDBChip>
            </div>
            <div className="usedetails-data" >
              <MDBChip>
                <span className="me-1">Height: </span><b>{location.state.height}{location.state.height ? "cms" : "--"}</b>
              </MDBChip>
            </div>
            <div className="usedetails-data" >
              <MDBChip>
                <span className="me-1">Blood Group: </span><b>{location.state.bloodgroup ? location.state.bloodgroup : "--"}</b>
              </MDBChip>
            </div>

            <div className="usedetails-data" >
              <MDBChip>
                <span className="me-1">Email: </span><b>{location.state.email ? location.state.email : "--"}</b>
              </MDBChip>
            </div>
          </div>
          <div className="usedetails">
            <div className="usedetails-data" >


              <span className="me-1">{membership.membershiptype === "addon" ? "Addon:" : "Plan:"}</span><b>
                {membership.name ?
                  membership.name
                  : "No Plan Associated"}
                {(allData.length > 0 && !allData[0].issuspend && membership._id) && (
                  <>
                    <span >
                      <button
                        className={parseFloat(expiresin) > 6 ? "clr-green px-1 button-text" : [parseFloat(expiresin) > 0 ? "clr-orange button-text" : "clr-red button-text"]}
                        href="#"
                        onClick={(e) =>
                          preaddUser({
                            member: location.state,
                            planId: membership._id || -1,
                          })
                        }
                      >
                        ({expiresin})
                      </button>
                    </span>
                    <button className="button-link link-color px-1"
                      onClick={(e) =>
                        preaddUser({
                          member: location.state,
                          planId: membership._id || -1,
                        })
                      }
                    >
                      Renew
                    </button>
                  </>
                )}
              </b>
            </div>
            {/* {(allData && allData.length > 0) && !allData[0].suspend && LatestMTrans.length > 0
            && <MemberShipChange lastTras={LatestMTrans} details={location.state} />} */}
          </div>

          <MDBTabs className="mb-3">
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab1")}
                active={basicActive === "tab1"}
              >
                Plans
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab2")}
                active={basicActive === "tab2"}
              >
                Addons
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab3")}
                active={basicActive === "tab3"}
              >
                Payments/Transactions
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab4")}
                active={basicActive === "tab4"}
              >
                Attendance
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab5")}
                active={basicActive === "tab5"}
              >
                WhatsApp History
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
          <MDBTabsContent>
            <MDBTabsPane show={basicActive === "tab1"} open={basicActive === "tab1"}>
              <MDBDatatable className="sno plansTable" fixedHeader maxHeight="83vh" data={mPData} entries={25} />
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === "tab2"} open={basicActive === "tab2"}>
              <MDBDatatable className="sno" fixedHeader maxHeight="83vh" data={mAData} entries={25} />
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === "tab3"} open={basicActive === "tab3"}>
              <MDBDatatable className="sno pymentsTable" fixedHeader maxHeight="83vh" data={mPMData} entries={25} />
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === "tab4"} open={basicActive === "tab4"}>
              <MDBDatatable className="sno" fixedHeader maxHeight="83vh" data={mATData} entries={25} />
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === "tab5"} open={basicActive === "tab5"}>
              <MDBDatatable className="sno whatsapphistorytable" fixedHeader maxHeight="83vh" data={basicData} entries={25} />
            </MDBTabsPane>

          </MDBTabsContent>
        </div>
        {openPayment && (
          <Popup
            title="Payment Information"
            ptype="modal-lg"
            content={
              <>
                <div>
                  <Payments onsubmit={onPayemntDone} oncancel={oncancelpayment} handleTransdate={handleTransdate} />
                </div>
              </>
            }
            handleClose={oncancelpayment}
          />
        )}
        {openReceipt && (
          <Popup
            title="Print Receipt"
            ptype="modal-lg"
            content={
              <>
                <div>
                  <ReceiptPrint gotohome={gotohome} transactionDate={transDate} />
                </div>
              </>
            }
            handleClose={gotohome}
          />
        )}
        {editTrans && (
          <Popup
            className="modal-body-overflow"
            title="Edit Membership Details "
            ptype="modal-md"
            style="overflow_inh"
            content={
              <>
                <EditMemberShip
                  editTrans={editTrans}
                  details={allData.length > 0 ? allData[0] : location.state}
                  cancel={(e) => seteditTrans()}
                />
              </>
            }
            handleClose={seteditTrans}
          />
        )}
        {isTransOpen && (
          <Popup
            title="Transaction Details"
            content={
              <>
                <TransactionDetails
                  oncancel={closeTransPopup}
                  // onAssociate={openAUserPop}
                  id={tId}
                  Rtoken={Rid}
                  transid={transid}
                  filters={"mdetails"}
                />
              </>
            }
            handleClose={closeTransPopup}
          />
        )}
        {logtimeShow && (
          <SubPopup
            title={value !== null ? value : "Log Time"}
            ptype="modal-md"
            content={
              <>
                <div>
                  <LogATime
                    data={selData}
                    value={value}
                    oncancel={closeLogTime}
                    expires={
                      getExpiryDate(selData)
                        ? getExpiryDate(selData).includes('Expired (Overdue)')
                          ? 'Expired (Overdue)'
                          : getExpiryDate(selData).includes('Expired')
                            ? 'Expired'
                            : `Expires: ${getExpiryDate(selData)}`
                        : 'No Plan Associated'
                    }
                    onsave={checkMember}
                  />
                </div>
              </>
            }
            handleClose={closeLogTime}
          />
        )
        }
        {isOpen && (
          <Popup
            title="Add Member"
            ptype="modal-lg"
            className="modal-body-overflow"
            content={
              <>
                <div className="modal-body-overflow">
                  <CreateMember
                    id={null}
                    index={null}
                    onsubmit={onsubmit}
                    backallback={createMemberCallback}
                    submit="0"
                    mId={mId}
                    // addandSave={addandSave}
                    from={"memberdetails"}


                  />
                </div>
              </>
            }
            handleClose={togglePopup}
          />
        )
        }

        <MDBModal tabIndex='-1' open={centredModal} setOpen={setCentredModal}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Activate Member</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <label>
                  <b className="title">{location.state.fname + " " + location.state.lname}</b>
                  {location.state.number ? (", " + location.state.number) : ""}{location.state.age ? (", " + location.state.age + "y") : ""}
                </label>
                {/* <label><b>Rakesh Kolla</b>, 9848173866, 25y</label> */}
                <p className="mt-2">This Member will be <strong>Activated</strong> again. Are you sure you want to activate this account?</p>
              </MDBModalBody>
              <MDBModalFooter className="pe-2 pb-2">
                <MDBBtn color='secondary' onClick={toggleOpen}>Cancel</MDBBtn>
                <MDBBtn onClick={activateFunction}>Activate</MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <MDBModal open={basicModal3} setOpen={setBasicModal3} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Suspend</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleOpen3}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody className="overflow_inh">
                <div>
                  <label>
                    <b className="title">{location.state.fname + " " + location.state.lname}</b>
                    {location.state.number ? (", " + location.state.number) : ""}{location.state.age ? (", " + location.state.age + "y") : ""}
                  </label>
                  <p className="my-2">This action will deactivate this member. Are you sure you want to <strong>suspend</strong>?</p>
                  <label className="fw-bold">Reason</label>
                  <MDBTextArea id='textAreaExample' rows={3} onChange={(e) => handlerReason(e)} />
                  {errors !== "" && <p className="clr-red">{errors}</p>}
                </div>
              </MDBModalBody>
              <MDBModalFooter className="pe-3 pb-2">
                <MDBBtn color='secondary' onClick={toggleOpen3}>
                  Cancel
                </MDBBtn>
                <MDBBtn color="danger" onClick={(e) => suspendSubmitFunction(e)}>Suspend</MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

      </>}</>
  );
}

