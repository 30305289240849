import { parseISO } from "date-fns";
import { MDBAlert, MDBBtn, MDBDatatable, MDBIcon, MDBInput, MDBModalFooter, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane, MDBTooltip } from "mdb-react-ui-kit";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import { useLocation, useNavigate } from "react-router";
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import Loader from "../components/Loader";
import Popup from "../components/Popup";
import TransactionDetails from "../components/TransactionDetails";
import ExcelExportButton from "../components/exportToExcel.js";
import { deletePaymentLinkTrans, deleteTransactions, getAllPaymentTransactions, getCheckStaff, getGymAddonsList, getPlans, getTokenCount, getTransactions, logEvent, sendPaymentLink } from "../services/apiServices";
import calImg from "./../components/images/calendar.svg";
import AddUsers from "./AddUsers";
import PageError from "./PageError";
import { googleAnalytic } from "../components/googleAnalytics";

const Transctions = () => {
  const location = useLocation();
  const [plans, setPlans] = useState("");
  const [addons, setAddons] = useState([]);
  const [apiError, setApiError] = useState(false);
  const [mfilter, setMfilter] = useState({
    inputText: "",
    // planNme: "",
    tidName: "",
    contNme: "",
    startDate: "",
    endDate: "",
  });
  const [Alerttxt, setAlerttxt] = useState("");
  const [PaymentLinkTrans, setPaymentLinkTrans] = useState([])
  const [filterr, setFilterr] = useState(false);
  const [staff, setStaff] = useState([])
  const today = new Date();
  const currentTime = new Date();
  const startOfToday = new Date(currentTime);
  startOfToday.setHours(0, 0, 0, 0);

  // Set end of today
  const endOfToday = new Date(currentTime);
  endOfToday.setHours(23, 59, 59, 999);

  const yesterday = new Date(currentTime);
  yesterday.setDate(currentTime.getDate() - 1);
  const startOfYesterday = new Date(yesterday);
  startOfYesterday.setHours(0, 0, 0, 0);

  const endOfYesterday = new Date(yesterday);
  endOfYesterday.setHours(23, 59, 59, 999);

  const startOfWeek = new Date(currentTime);
  startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
  startOfWeek.setHours(12, 0, 0, 0);
  const endOfWeek = new Date(currentTime);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(12, 0, 0, 0);

  const startOfMonth = new Date(currentTime);
  startOfMonth.setDate(1);
  startOfMonth.setHours(0, 0, 0, 0);

  const endOfMonth = new Date(currentTime);
  endOfMonth.setMonth(currentTime.getMonth() + 1, 0);
  endOfMonth.setHours(23, 59, 59, 999);

  const startOfYear = new Date(currentTime);
  startOfYear.setMonth(0, 1);
  startOfYear.setHours(0, 0, 0, 0);

  const endOfYear = new Date(currentTime);
  endOfYear.setMonth(11, 31);
  endOfYear.setHours(23, 59, 59, 999);

  today.setHours(23, 59, 0, 0);
  const [dateRange, setDateRange] = useState([startOfMonth, today]);
  const [startDate, endDate] = dateRange;

  const [loading, setLoading] = useState(true);
  const [userData, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteTrans, setDeleteTrans] = useState(null);
  const [openAUser, setOpenAUser] = useState(false);
  const [tId, setTId] = useState(null);
  const [transid, settransid] = useState("")
  const [Rid, setRid] = useState(null);
  const [basicActive, setBasicActive] = useState("tab1");
  const [totalCount, setTotalcount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [Date11, setDate11] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [Date22, setDate22] = useState(new Date());
  const [valuee, setValuee] = useState(-1);
  const [totalPages, setTotalPages] = useState(1);
  const [userRData, setuserRData] = useState([])
  const [applyFilterClicked, setApplyFilterClicked] = useState(false);
  const [DayFilter, setDayFilter] = useState("4");
  const [customdate, setcustomdate] = useState(DayFilter === "6");
  const [totalTranactions, setTotalTransaction] = useState(0)
  const [totalTranactionsCount, setTotalTractionCount] = useState(0)
  const [paymentLinkFilter, setpaymentLinkFilter] = useState(0)
  const [paymentLink, setPaymentLink] = useState(null);
  const [number, setNumber] = useState("");
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  // console.log(location, '8888888888888888');
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  useEffect(() => {
    setcustomdate(DayFilter === "6" ? true : false);
  }, [DayFilter]);

  // console.log(endDate);

  async function transactionDelete(e, data) {
    e.preventDefault();
    let id = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";

    await deleteTransactions(id, { data: data });

    let ip = localStorage.getItem("ipaddress");
    let browserinfo = browserName + "-" + browserVersion + ".0.0";
    let flag = `Transaction Deleted Successfull (${data[0].tId})`;
    let Eventtype = "Transaction";
    let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
    let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
    await googleAnalytic("Transactions", 'Transaction', 'Delete Button', 'delete Transaction')
    // ReactGA.event({
    //   category: 'Transaction',
    //   action: 'Delete Button',
    //   label: 'delete Transaction',
    // });
    logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
    setData(prevState => {
      const updatedValue = prevState.filter(obj => obj.tId !== data[0].tId);
      return updatedValue;
    });
    setDeleteTrans(null);
  }

  function getToken() {
    try {
      const tokenString = localStorage.getItem("transactionId");
      return tokenString;
    }
    catch (e) {
      console.log("error :::::: " + e);
      return;
    }
  }

  function closeAddPopup() {
    setOpenAUser(false);
    setTId(-1);
  }

  function openAUserPop() {
    setOpenAUser(true);
  }
  let dashboardFilter = false;
  // localStorage.removeItem("transaction_filter")
  const handleResetAndToggle = () => {
    dashboardFilter = true;
    localStorage.removeItem("transaction_filter");
    setCurrentPage(1)
    setMfilter({
      // planNme: "",
      tidName: "",
      contNme: "",
      startDate: "",
      endDate: "",
      // inputText: "",
    });

    setDateRange([startOfMonth, endOfMonth])
    setValuee(-1)
    setDate22(new Date())
    setDate11(new Date())
    // fetchData()
    setDayFilter("4")
    setApplyFilterClicked(false);

    setFilterr((prevFilterr) => !prevFilterr);
    fetchData();
    setpaymentLinkFilter(0);
    return;
  };


  async function fetchData(sTxt) {
    if (localStorage.getItem("loggedUserInfo")) {

      console.log(location);
      console.log(window.location.pathname);
      let locfilter = JSON.parse(localStorage.getItem("transaction_filter"));

      let locfilter1 = JSON.parse(localStorage.getItem("transaction_filter1"))
      console.log(locfilter1);
      if (locfilter1) {
        location.state = {}
        location.state.data = locfilter1.dashboardFID;
        location.state.fdate = locfilter1.dashboardFfdate;
        location.state.tdate = locfilter1.dashboardFtdate;
        setDayFilter(locfilter1.dashboardDayFilter)
      }
      if (location.state && location.state.Dayfilter) {
        setDayFilter((location.state && location.state.Dayfilter ? location.state.Dayfilter : DayFilter))
      } else if (locfilter && locfilter.filters.DayFilter) {
        setDayFilter(locfilter && locfilter.filters.DayFilter !== "" ? locfilter.filters.DayFilter : DayFilter)
      }


      location.filter = {};
      location.filter.tidName = locfilter && locfilter.filters && locfilter.filters.tidName !== "" ? locfilter.filters.tidName : mfilter.tidName
      location.filter.contNme = locfilter && locfilter.filters && locfilter.filters.contNme !== "" ? locfilter.filters.contNme : mfilter.contNme

      location.limitmember = locfilter && locfilter.itemsPerPage ? locfilter.itemsPerPage : itemsPerPage;
      location.currentPage = locfilter && locfilter.currentPage ? locfilter.currentPage : (currentPage > 0 ? currentPage : 1);

      setMfilter({
        tidName: locfilter && locfilter.filters && locfilter.filters.tidName !== "" ? locfilter.filters.tidName : "",
        contNme: locfilter && locfilter.filters && locfilter.filters.contNme !== "" ? locfilter.filters.contNme : "",
        startDate: "",
        endDate: "",
      })


      const dayFilter = locfilter && locfilter.filters.DayFilter !== "" ? locfilter.filters.DayFilter : (location.state && location.state.Dayfilter ? location.state.Dayfilter : DayFilter);
      const sDate = locfilter && locfilter.filters.startDate ? new Date(locfilter.filters.startDate) : (location.state && location.state.fdate ? location.state.fdate : startDate);
      const eDate = locfilter && locfilter.filters.endDate ? new Date(locfilter.filters.endDate) : (location.state && location.state.tdate ? location.state.tdate : endDate)
      console.log(dayFilter, sDate, eDate);


      setFilterr(locfilter && locfilter.filters.filtercheck !== "" ? locfilter.filters.filtercheck : filterr)
      if (locfilter && locfilter.filters.DayFilter) {
        if (locfilter && locfilter.filters.DayFilter !== "7") {
          setDateRange([locfilter && locfilter.filters.startDate ? new Date(locfilter.filters.startDate) : startDate, locfilter && locfilter.filters.endDate ? new Date(locfilter.filters.endDate) : endDate])
        } else {
          setDateRange([startOfMonth, endOfMonth])
        }
      }
      let startOfDate = (dayFilter === "6" ? sDate : (dayFilter === "7" ? "" : (dayFilter === '1' ? startOfToday : (dayFilter === '2' ? startOfYesterday : (dayFilter === "3" ? startOfWeek : (dayFilter === "4" ? startOfMonth : (dayFilter === "5" ? startOfYear : (dayFilter === "6" ? sDate : ""))))))))
      let endOfDate = (dayFilter === "6" ? eDate : (dayFilter === "7" ? "" : (dayFilter === '1' ? endOfToday : (dayFilter === '2' ? endOfYesterday : (dayFilter === "3" ? endOfWeek : (dayFilter === "4" ? endOfMonth : (dayFilter === "5" ? endOfYear : (dayFilter === "6" ? eDate : ""))))))))

      if (dashboardFilter) {
        location.state = null
        location.filter.inputText = ""
        location.filter.inputText = ""
        location.filter.planNme = ""
        location.filter.tidName = ""
        location.filter.contNme = ""
        startOfDate = startOfMonth
        endOfDate = endOfMonth
        setDayFilter("4")
      }
      console.log(startOfDate, endOfDate);
      // if (location.state && location.state.Dayfilter) {
      setDateRange([startOfDate, endOfDate])
      // }


      //the below code is converting in to indian time to us time and sending to backend in the backend also not converting  the date and time
      if ((startOfDate && endOfDate)) {
        const startDatef = new Date(startOfDate)
        startDatef.setHours(0, 0, 0, 0)
        const utcstartDate = startDatef.toISOString();
        location.filter.startDate = utcstartDate;
        let lastoftheday = new Date(endOfDate);
        lastoftheday.setHours(23, 59, 59, 999);
        const utcEndDate = lastoftheday.toISOString();

        location.filter.endDate = utcEndDate;
        console.log(utcstartDate, utcEndDate);

      }

      console.log(location);
      const response = await getTransactions(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
        location
      );

      if (!response) {
        return;
      }
      if (response === "false") {
        setApiError(true)
      } else {


        const usersData = await response.json();
        if (!usersData) {

          navigate("/");
          return;
        }

        console.log(usersData);

        // setTotalMemberCount(usersData.uniqueMemberCount)
        setTotalTractionCount(usersData.uniqetotalTransactionsCount ? usersData.uniqetotalTransactionsCount : 0)
        setData(usersData.transctions);
        setTotalTransaction(usersData.totalPayment ? parseFloat((usersData.totalPayment).toFixed(2)) : 0)
        setTotalPages(usersData.transctions && usersData.transctions.length > 0 ? usersData.totalPages : 1)
        setTotalcount(usersData.totalTransactionsCount ? usersData.totalTransactionsCount : 0)
        // }
        if (currentPage > ((usersData.transctions && usersData.transctions.length > 0) ? usersData.totalPages : 1)) {
          setCurrentPage(1)
        }
        setLoading(false)
        setApplyFilterClicked(false)
        setValuee(location && location.state && location.state.data);
        setDate11(location && location.state && location.state.fdate);
        setDate22(location && location.state && location.state.tdate);
        localStorage.removeItem("transaction_filter1");
      }
    }
  }

  const downloaddata = (data) => {
    if (basicActive === "tab1") {
      fetchDataAndExport();
    } else {
      ExcelExportButton(data, "Refund_data");
    }

  }
  const fetchDataAndExport = async () => {
    // location.limitmember = totalCount;
    // location.currentPage = 1;
    const exportfilters = location;
    exportfilters.limitmember = totalCount;
    exportfilters.currentPage = 1;
    const response = await getTransactions(
      JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
      exportfilters
    );

    if (!response) {
      return;
    }
    if (response === "false") {
      setApiError(true)
      return;
    }

    const transData = await response.json();
    console.log(transData);
    if (!transData) {
      return;
    }

    let tmrow = [];
    let uniqueTIds = new Set();
    const usersData = transData.transctions && transData.transctions.length > 0 ? transData.transctions : [];
    if (usersData.length > 0) {

      usersData.forEach((data1, index) => {
        const alldata = data1.alldata;
        const groupedMembers = alldata;
        alldata.forEach((data, index) => {
          if (!uniqueTIds.has(data.tId) && data.isplan !== "3") {
            let taxamount = 0;
            const uniqueItemIdsSet = new Set();
            groupedMembers.filter(item => {
              if (!uniqueItemIdsSet.has(item.itemId)) {
                item.payment.taxamounts && (taxamount += item.payment.taxamounts.reduce((acc, itm) => acc + (itm.amount ? itm.amount : 0), 0));
                uniqueItemIdsSet.add(item.itemId);
                return true;
              }
              return false;
            });

            let tokenCount = 0;
            groupedMembers.forEach((member, memberIndex) => {
              let rarray = [];
              let token = data.token;
              if (memberIndex > 0) {
                tokenCount++;
                token = token + "-" + tokenCount;
              } else {
                tokenCount = 1;
                token = token + "-" + tokenCount
              }

              let totalPercentage = member.payment.taxamounts.reduce((total, tax) => {
                if (tax.percentage) {
                  let percentage = parseFloat(tax.percentage.replace('%', ''));
                  return total + percentage;
                }
                return total;
              }, 0);

              let tPlans = member.plan && member.plan.name ? member.plan.name : (member.addons && member.addons.length > 0 ? member.addons[0].name : "");
              let variableTaxCheck = member.payment.taxamounts.find(tax => tax.TaxCheck)?.TaxCheck || '';
              const amount = ((member.plan && member.plan.price) ? member.plan.price : (member.addons.length > 0 ? member.addons[0].props[0].propvalue : 0))
              let totalTaxAmount = amount * (totalPercentage / 100);
              const totalamount = variableTaxCheck === 'Excluded' ? amount + totalTaxAmount : amount
              const duePercentage = ((member.payment.totalamount - member.payment.paid) / member.payment.totalamount) * 100;
              const dueAmount = (duePercentage / 100) * totalamount;

              rarray.push(token);
              rarray.push(member.members ? `${member.members.fname} ${member.members.lname}` : "");
              rarray.push(parseISO(data.createdAt));
              rarray.push(parseISO(data.updatedAt));
              rarray.push(tPlans);
              rarray.push(Number(member.payment.discount ? member.payment.discount.amount : 0));
              rarray.push(Number(dueAmount.toFixed(2)));
              rarray.push(Number(totalamount));
              rarray.push(1);
              tmrow.push(rarray);
            });
            uniqueTIds.add(data.tId);
          }
        });
      });
    }
    console.log(tmrow);
    const updatedBasicData = {
      columns: [
        "OrderId",
        "Member(s)",
        "Created Date",
        "Updated Date",
        "Plan/Addon",
        "Discount",
        "Due",
        "Total (Incl.tax)",
        "Quantity",

      ],
      rows: tmrow.length > 0 && tmrow,
    };
    ExcelExportButton(updatedBasicData, "Transactions_Data");

  }

  const togglePopup = (event, tId, _id, id) => {
    event.preventDefault();
    setTId(tId);
    setIsOpen(!isOpen);
    setRid(id);
    settransid(_id)
  };

  const closePopup = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };


  useEffect(() => {

    fetchData();
  }, [currentPage, itemsPerPage, applyFilterClicked]);
  function resendPaymentHandler(e, data) {
    e.preventDefault();
    console.log(data);
    setNumber(data.number);
    setPaymentLink(data);
  }
  async function resendPayment(e, data) {
    e.preventDefault();
    console.log(data);
    let updates = {
      number: number,
      amount: data.amount,
      gymid: data.gymid,
      tid: data.tId,
      membName: data.membername,
      clientUrl: window.location.origin,
      transdetails: data.transdetails,
    };
    console.log(updates);
    deletePaymentLinkTrans(data._id);
    let response = await sendPaymentLink(data.gymid, updates);
    if (response.status === 200) {
      let resObj = await response.json();
      console.log(resObj);
      console.log("----------------------------------------------------")
      console.log("generated payment url::", resObj.genratedpaymentLink);
      console.log("----------------------------------------------------")

      setPaymentLinkTrans(prevState =>
        prevState.map(obj =>
          obj._id === data._id ? { ...obj, status: 0, number: updates.number, _id: resObj.response._id, createdAt: new Date() } : obj
        )
      );
      if (resObj.error) {
        if (resObj.error == "error in generating link") {
          setAlerttxt("Failed to generate payment link. try again");

        } else {
          setAlerttxt("Failed to send SMS of payment link. try again");
        }
      }
      if (resObj.response && resObj.response._id) {
        setAlerttxt("payment link sent");
      }
    }
    setNumber("");
    setPaymentLink(null);
  }
  useEffect(() => {
    if (basicActive === "tab3") {
      fetchPLData();
    }
  }, [basicActive])
  useEffect(() => {
    async function fetchPData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getPlans(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {
          const plans = await response.json();

          if (!plans) {
            return;
          }
          setPlans(plans);
        }
        const resp = await getGymAddonsList(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!resp) {
          return;
        }
        if (resp === "false") {
          setApiError(true)
        } else {
          setAddons(await resp.json());
          setLoading(false)
        }
        const res = await getTokenCount(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);

        if (!res) {
          return;
        }

        if (res === "false") {
          setApiError(true)
        } else {
          const usersData = await res.json();
          setuserRData(usersData)
          console.log(usersData);

        }
      }
    }

    fetchPLData();
    fetchPData();
  }, [])
  async function fetchPLData() {
    if (JSON.parse(localStorage.getItem("loggedUserInfo"))) {
      const res = await getAllPaymentTransactions(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
      if (!res) {
        return;
      }
      if (res === "false") {
        setApiError(true)
      } else {
        let usersData = await res.json();
        if (usersData.length > 0) {
          usersData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
        setPaymentLinkTrans(usersData);
        console.log("PaymentLinkTransactions::: ", usersData);
      }
    }
  }

  useEffect(() => {
    const fetchstaffData = async () => {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getCheckStaff(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {
          const usersData = await response.json();
          setStaff(usersData)
        }
      }
    }
    fetchstaffData();
  }, [])
  const filteredobject = {
    filters: {
      tidName: mfilter.tidName,
      contNme: mfilter.contNme,
      startDate: endDate,
      endDate: startDate,
      DayFilter: DayFilter,
      apply: true,
      filtercheck: true
    },
    dashboardDayFilter: DayFilter,
    dashboardFID: valuee,
    dashboardFfdate: Date11,
    dashboardFtdate: Date22,
  }

  function handleClik(data) {
    localStorage.setItem("transaction_filter1", JSON.stringify(filteredobject))
    let selMember = data.members

    selMember.Date11 = Date11;
    selMember.Date22 = Date22;
    selMember.anotherColumn = valuee;
    selMember.filters = {
      inputText: mfilter.inputText,
      planNme: mfilter.planNme,
      tidName: mfilter.tidName,
      contNme: mfilter.contNme,
      startDate: startDate,
      endDate: endDate,
      DayFilter: DayFilter
    }
    selMember.transactiontype = 3;
    selMember.addon = [];
    selMember.address = data.members && data.members.address ? data.members.address : "";
    selMember.age = data.members.age ? data.members.age : "";
    selMember.bId = data.members.bId;
    selMember.bloodgroup = data.members.bloodgroup || "";
    selMember.city = data.members.city ? data.members.city : "";
    selMember.country = data.members.country ? data.members.country : "";
    selMember.createdAt = data.members.createdAt;
    selMember.email = data.members.email ? data.members.email : "";
    selMember.expirydate = data.members.expirydate;
    selMember.fname = data.members && data.members.fname;
    selMember.height = data.members && data.members.height ? data.members.height : null;
    selMember.lname = data.members && data.members.lname;
    selMember.mID = data.members.mID;
    selMember.member = data.members.member ? data.members.member : "";
    selMember.number = data.members.number;
    selMember.others = data.members.others ? data.members.others : "";
    selMember.plan = data.plan || [];
    selMember.planid = data.members.planid;
    selMember.referral = data.members.referral;
    selMember.sex = data.members.sex;
    selMember.startdate = data.members.startdate;
    selMember.updatedAt = data.members.updatedAt;
    selMember.weight = data.members.weight || null;
    selMember.__v = 0;
    selMember._id = data.members._id;
    selMember.return = "transactions"

    console.log(selMember);
    navigate("/mdetails", {
      state: selMember,
    });
  }

  let tmrow = [];
  const usersData = userData
  let uniqueTIds = new Set();
  usersData && usersData.length > 0 && usersData.forEach((data1, ind) => {
    const alldata = data1.alldata

    const groupedMembers = alldata

    // console.log(groupedMembers);
    let rarray = [];
    alldata.forEach((data, index) => {
      // console.log(data,'867777466');
      if (!uniqueTIds.has(data.tId) && data.isplan !== "3") {

        let taxamount = 0
        const uniqueItemIdsSet = new Set();
        groupedMembers.filter(item => {
          if (!uniqueItemIdsSet.has(item.itemId)) {
            item.payment.taxamounts && (taxamount += item.payment.taxamounts.reduce((acc, itm) => acc + (itm.amount ? itm.amount : 0), 0));
            uniqueItemIdsSet.add(item.itemId);
            return true;
          }
          return false;
        });



        const memberIdSet = new Set();


        const uniqueTransactions = groupedMembers.filter(transaction => {

          if (!memberIdSet.has(transaction.members._id)) {

            memberIdSet.add(transaction.members._id);
            return true;
          }

          return false;
        });
        const memberNames = uniqueTransactions.map(item => item.members ? (item.members.fname + " " + item.members.lname) : "");
        // console.log(uniqueTransactions);
        let tPlans = groupedMembers.map((itm) => (itm.plan && itm.plan.name) ? itm.plan.name : (itm.addons && itm.addons.length > 0 ? itm.addons[0].name : ""));
        let tdiscount = groupedMembers.reduce((acc, itm) => acc + ((itm.payment.discount && itm.payment.discount.amount) ? itm.payment.discount.amount : 0), 0)
        const uniquePlanNames = [...new Set(tPlans)];
        // console.log(memberNames);

        const staffName = staff.length > 0 && staff.filter((item) => item._id === data.staffid)[0]
        // console.log(staffName);
        rarray.push(ind + 1 + ((currentPage - 1) * itemsPerPage))
        rarray.push(
          <button className="button-link link-color" onClick={(event) => togglePopup(event, data.tId, data._id)}>{data.token ? data.token : data.tId}</button>
        );

        rarray.push(<MDBTooltip tag='div'
          title={data.members && memberNames.join(", ")} >
          <span className="d-flex">
            {uniqueTransactions.length >= 1 &&
              uniqueTransactions.map((item, index) =>
                <div className="link-color " onClick={() => handleClik(item)}> {item.members && item.members.fname + " " + item.members.lname}{uniqueTransactions.length === (index + 1) ? " " : ","}{" "} </div>
              )
              // :
              // <div > {data.members && memberNames.length > 1 ? memberNames.join("") : memberNames.join(",")}</div>
            }</span>
        </MDBTooltip>);
        rarray.push(<span className='trans-date'>{new Date(data.createdAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", })}</span>);
        rarray.push(<span className='trans-date'>{new Date(data.updatedAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", })}</span>);
        rarray.push(<MDBTooltip tag='div'
          title={uniquePlanNames.join(", ")} >
          {uniquePlanNames.join(", ")}
        </MDBTooltip>);
        // rarray.push(uniquePlanNames.join(", "));
        // rarray.push("₹" + ((data.payment.paid).toLocaleString('en-IN')));
        rarray.push("₹" + (tdiscount).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 2 }));
        // rarray.push("₹" + (data.payment.taxamounts ? taxamount : (data.payment.taxamt ? data.payment.taxamt : 0)).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 2 }));
        rarray.push("₹" + data.payment.balance.toLocaleString('en-IN'));
        rarray.push("₹" + data.payment.totalamount.toLocaleString('en-IN'));

        rarray.push(groupedMembers.length);
        rarray.push(staffName ? (staffName.firstname || staffName.lastname ? staffName.firstname + " " + staffName.lastname : staffName.username) : "")
        rarray.push(<MDBIcon fas icon="trash" onClick={(e) => setDeleteTrans(groupedMembers)} />);
        tmrow.push(rarray);
        uniqueTIds.add(data.tId);
      }
    })
  });


  const basicData = {
    columns: [
      "S.no",
      "Order Id",
      "Member(s)",
      "Created Date",
      "Updated Date",
      "Plan/Addon",
      // "Paid (incl.tax)",
      "Discount",
      // "Tax",
      "Due",
      "Total (Incl.tax)",
      // "Contact",

      "Quantity",
      "Staff",
      "Delete",
    ],
    rows: tmrow,
  };

  const CalenderInput = forwardRef(({ value, onClick }, ref) => (
    <>
      <span className="form-control select-input  placeholder-active me-3 shadow-1" onClick={onClick}>
        <p className="d-inline m-0 me-2">
          {value === "" ? "Custom Range" : value}
        </p>
        <img src={calImg} ref={ref} alt="Custom Range" title="Select Custom Range" />
      </span>
    </>
  ));
  const basicDataR1 = userRData


  let tmrowR = [];
  let uniqueTIdsR = new Set();
  basicDataR1.length > 0 && basicDataR1.forEach((data, index) => {
    // const groupedMembers = usersData.filter(item => (item.tId === data.tId));
    const memberNames = data.members ? (`${data.members.fname} ${data.members.lname}`) : false;
    const planNames = data.itemid
      ? (plans.find(item => item._id === data.itemid)
        ? plans.find(item => item._id === data.itemid).name
        : (addons.find(item => item._id === data.itemid)
          ? addons.find(item => item._id === data.itemid).name
          : false))
      : false;
    // const uniquePlanNames = [...new Set(planNames)];
    if (!uniqueTIdsR.has(data.tId) && data.refund) {
      const sortedRefund = data.refund.length > 0 && data.refund.slice().sort((a, b) => {
        const createdAtA = new Date(a.date);
        const createdAtB = new Date(b.date);
        return createdAtB - createdAtA;
      });
      sortedRefund && sortedRefund.forEach((obj) => {
        let rarrayr = [];
        rarrayr.push(
          <button className="button-link link-color" onClick={(event) => togglePopup(event, data.tId, data._id
          )}>
            {obj.token ? obj.token : data.tId}
          </button>
        );
        rarrayr.push(
          new Date(obj.date).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })
        );
        rarrayr.push(
          data.isplan === "3"
            ? "Balance Payment"
            : planNames
          // uniquePlanNames.join(", ")
        );
        rarrayr.push(obj.refundamount ? "₹" + obj.refundamount : "");
        rarrayr.push(obj.taxamt ? "₹" + obj.taxamt : "");
        rarrayr.push(obj.reason ? <MDBTooltip tag='div'
          title={obj.reason} >
          {obj.reason}
        </MDBTooltip> : "");
        rarrayr.push(
          data.members
          && data.members.number
          // : "Pay Transaction(" + data.ref_token ? data.ref_token : data.ref_id + ")"
        );

        rarrayr.push(
          data.members
            ? memberNames
            : "Pay Transaction(" + data.ref_id + ")"
        );
        rarrayr.push(data.quantity);
        tmrowR.push(rarrayr);
      })
      uniqueTIdsR.add(data.tId);
    }
  });
  tmrowR.sort((a, b) => new Date(b[1]) - new Date(a[1]));
  const basicDataR = {
    columns: [
      'OrderId',
      "Date",
      "Plan/Addon",
      "Amount (Incl.tax)",
      "Tax",
      "Reason",
      "Contact",
      "Member(s)",
      "Quantity",
    ],
    rows: tmrowR,
  };
  const PaymentLinkTransfiletred = PaymentLinkTrans.length > 0 ? PaymentLinkTrans.filter((el) => {
    let updatedInfo = el;
    if (filteredobject.filters.tidName !== "") {
      let name = filteredobject.filters.tidName ? filteredobject.filters.tidName : mfilter.tidName;
      updatedInfo = updatedInfo && ((el.membername && el.membername.toLowerCase().includes(name)) ||
        (el.number && el.number.toLowerCase().includes(name)) ||
        (el.tId && el.tId.toLowerCase().includes(name)))
    }

    if (filteredobject.filters.contNme !== "") {
      updatedInfo = updatedInfo && (el.number && el.number.includes(filteredobject.filters.contNme));
    }
    if (paymentLinkFilter !== 0) {
      if (paymentLinkFilter === 1) {
        updatedInfo = updatedInfo && (el.status === 1);
      }
      if (paymentLinkFilter === 2) {
        // pending filters
        updatedInfo = updatedInfo && (el.status === 0);
      } if (paymentLinkFilter === 3) {
        // failed filters
        updatedInfo = updatedInfo && (el.status === 3);
      }
    }
    if (filteredobject.filters.DayFilter && filteredobject.filters.DayFilter !== "7") {
      let dayFilter = filteredobject.filters.DayFilter;
      const createdAtDate = new Date(el.createdAt);

      let startOfDate = (dayFilter === "6" ? startDate : ((dayFilter === '1' ? startOfToday : (dayFilter === '2' ? startOfYesterday : (dayFilter === "3" ? startOfWeek : (dayFilter === "4" ? startOfMonth : (dayFilter === "5" ? startOfYear : "")))))))
      let endOfDate = (dayFilter === "6" ? endDate : ((dayFilter === '1' ? endOfToday : (dayFilter === '2' ? endOfYesterday : (dayFilter === "3" ? endOfWeek : (dayFilter === "4" ? endOfMonth : (dayFilter === "5" ? endOfYear : "")))))))

      const start = new Date(startOfDate);
      let end = new Date(endOfDate);
      end.setHours(23, 59, 59, 999);
      updatedInfo = updatedInfo && createdAtDate >= start && createdAtDate <= end;
    }
    return updatedInfo;
  }) : [];

  let tmrowP = [];
  // PaymentLinkTransfiletred.length > 0 && PaymentLinkTransfiletred.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  PaymentLinkTransfiletred.length > 0 && PaymentLinkTransfiletred.forEach((data, index) => {

    let rarrayr = [];
    rarrayr.push(index + 1);
    // rarrayr.push(data.uniqueid ? data.uniqueid : "--");
    rarrayr.push(data.tId + (data.transdetails[0].isplan === 3 ? "-Due" : ""));
    // rarrayr.push(data.token ? data.token : "--");
    rarrayr.push(
      new Date(data.createdAt).toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
      })
    );
    rarrayr.push(data.number);
    rarrayr.push(data.amount);
    rarrayr.push(data.response && data.response.paymentInstrument ? data.response.paymentInstrument.type : "--");
    rarrayr.push(data.status === 1 ? "Success" : (data.status === 0 ? "Pending" :
      <div className="d-flex align-items-center">
        <div className="me-2">
          Failed
        </div>
        <div className="link-color" onClick={(e) => resendPaymentHandler(e, data)}>Resend</div>
      </div>
    ));
    tmrowP.push(rarrayr);
  })
  const basicDataP = {
    columns: [
      'S.no',
      // 'Link Id',
      'Transaction ID',
      // 'Token',
      "Date",
      "Number",
      "Amount",
      "Payment Type",
      "Status",
    ],
    rows: tmrowP,
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
    setLoading(true)
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
    setLoading(true)
  };


  let filter = {}

  const handleApplyAndToggle = () => {
    let startOfDate = (DayFilter === "6" ? startDate : (DayFilter === "7" ? "" : (DayFilter === '1' ? startOfToday : (DayFilter === '2' ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : ""))))))))
    let endOfDate = (DayFilter === "6" ? endDate : (DayFilter === "7" ? "" : (DayFilter === '1' ? endOfToday : (DayFilter === '2' ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : ""))))))))
    filter.inputText = mfilter.inputText
    filter.planNme = mfilter.planNme
    filter.tidName = mfilter.tidName
    filter.contNme = mfilter.contNme
    filter.startDate = mfilter.startDate

    filter.endDate = mfilter.endDate

    console.log(startOfDate, endOfDate);
    setCurrentPage(1)
    const filteredobject = {
      filters: {

        tidName: mfilter.tidName,
        contNme: mfilter.contNme,
        startDate: new Date(startOfDate),
        endDate: new Date(endOfDate),
        DayFilter: DayFilter,
        apply: true,
        filtercheck: true
      },
      dashboardDayFilter: DayFilter,
      dashboardFID: valuee,
      dashboardFfdate: Date11,
      dashboardFtdate: Date22,
    }


    // console.log(filteredobject);

    if (basicActive === "tab1") {
      setApplyFilterClicked(true);
      setLoading(true)

      // fetchData();
    }
    localStorage.setItem("transaction_filter", JSON.stringify(filteredobject))
  }

  let RoleValue = JSON.parse(localStorage.getItem("loggedUserInfo"))
    ? (JSON.parse(localStorage.getItem("loggedUserInfo")).role
      ? JSON.parse(localStorage.getItem("loggedUserInfo")).role : "admin") : "staff";

  const handleReload = () => {
    window.location.reload();
  }


  return (
    <>
      <LeftPane isactive="8" />
      <Header />
      {apiError ? <PageError handleReload={handleReload} /> : <div
        className="gym-main rightContent"
      >
        <div
          className="gym-container"
          id="showcase"
        >
          <h3 className="gym-large">Transactions</h3>
          <div className=" ">
            {RoleValue !== "staff" &&
              <div className="mb-2 d-flex">
                <div className="filters_Count">
                  Total Amount Received: <b>&#8377;{totalTranactions.toLocaleString('en-IN')} </b>
                </div>
                <div className="filters_Count">
                  Total Tranactions Count: <b>{totalTranactionsCount} </b>
                </div>
                {/* <div className="filters_Count">
            Total Members Count: <b>{totalMemberCount} </b>
          </div> */}
              </div>}
            <MDBTabs>
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleBasicClick("tab1")}
                  active={basicActive === "tab1"}
                >
                  Transactions
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleBasicClick("tab2")}
                  active={basicActive === "tab2"}
                >
                  Refund
                </MDBTabsLink>
              </MDBTabsItem>
              {PaymentLinkTrans.length > 0 &&
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleBasicClick("tab3")}
                    active={basicActive === "tab3"}
                  >
                    Payment Links
                  </MDBTabsLink>
                </MDBTabsItem>}
            </MDBTabs>
          </div>
          <div className="searchFilterToggle">



            {loading && (<h4><Loader /></h4>)}
            {(basicActive === "tab1" || basicActive === "tab3") &&
              <>
                <div className=" d-flex align-items-center flex-wrap w-sm-80 mb-3 mobileResloution" >
                  <div className="gym-section order-0">
                    <label className="fw-bold">Search by {basicActive === "tab1" ? "OrderId" : ""}/Transactions Id/Members</label>
                    <div>
                      <input
                        type="text"
                        // ref={searchRef}
                        value={mfilter.tidName}
                        className="form-control select-input  placeholder-active me-3 shadow-1  "
                        placeholder="search here..."
                        onChange={(e) =>
                          setMfilter({
                            ...mfilter,
                            tidName: e.target.value
                          })}
                      // setTidName(e.target.value.toLowerCase())}
                      />
                    </div>
                  </div>
                  <div className="gym-section order-1 ms-3">
                    <label className="fw-bold">Contact</label>
                    <div>
                      <input
                        type="number"
                        // ref={searchRef}
                        value={mfilter.contNme}
                        className="form-control select-input  placeholder-active me-3 shadow-1  "
                        // className="gym-input forminput d-inline-block "
                        placeholder="search here..."
                        onChange={(e) =>
                          setMfilter({
                            ...mfilter,
                            contNme: e.target.value
                          })}
                      />
                    </div>
                  </div>
                  {basicActive === "tab3" &&
                    <div className="gym-section w-100-mobile ms-2">
                      <label className="fw-bold">Status</label>
                      <div>
                        <select
                          className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile"
                          value={paymentLinkFilter}
                          onChange={(e) => setpaymentLinkFilter(e.target.value)}
                        >
                          <option value={0}>All</option>
                          <option value={1}>Success</option>
                          <option value={2}>Pending</option>
                          <option value={3}>Failed</option>
                        </select>
                      </div>
                    </div>}
                  <div className="mob-w-100 ms-3 mob-m-0 ">
                    <label className="me-2 fw-bold"> Days </label>
                    <div>
                      <select
                        name="shrs"
                        id="shr"
                        className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile"
                        onChange={(e) => setDayFilter(e.target.value)}
                        value={DayFilter}
                      >
                        <option value="7">All</option>
                        <option value="1">Today</option>
                        <option value="2">Yesterday</option>
                        <option value="3">This Week</option>
                        <option value="4">This Month</option>
                        <option value="5">This Year</option>
                        <option value="6">Custom</option>
                      </select>
                    </div>
                  </div>

                  {customdate && <div className="gym-section order-2 ms-3">
                    <label className="fw-bold">Date</label>
                    <div className="gym-xlarge z-index_4 gym-larg-pos">
                      <div className="cursor-pointer">
                        <DatePicker
                          dateFormat={"d/M/yyyy"}
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            setDateRange(update);
                          }}
                          isClearable={false}
                          customInput={<CalenderInput />}
                        />
                      </div>
                    </div>
                  </div>}
                  <div className="gym-section order-5 ms-3 " >
                    <div className="mt-3">
                      {basicActive === "tab1" && <button type="submit" className="me-2 btn btn-primary"
                        onClick={(e) => handleApplyAndToggle()}
                      >
                        Apply
                      </button>}
                      <button type="submit" className="mt-2 btn btn-secondary"
                        onClick={(e) => handleResetAndToggle()}
                      >
                        Clear
                      </button>
                      {RoleValue === "admin" && basicActive === "tab1" &&
                        <button type="submit" className="ms-2 btnheight btn btn-primary"
                          onClick={(e) => downloaddata(basicActive === "tab1" ? basicData : basicDataR)}
                        >
                          <MDBIcon fas icon="download" className="d-inline me-1" />Export
                        </button>}
                    </div>
                  </div>
                </div>
              </>}
          </div>
        </div>


        <MDBTabsContent>
          <MDBTabsPane show={basicActive === "tab1"} open={basicActive === "tab1"}>
            <div className="datatable">
              <div className="TransactionsTable sno">
                <table className="table datatable-table">
                  <thead className="datatable-header">
                    <tr>
                      {basicData.columns.map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!basicData.rows && loading ? (<h4> <Loader /></h4>) : basicData.rows.map((row, rowIndex) => (
                      <tr key={rowIndex}  >
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                    {!loading && basicData.rows.length === 0 &&
                      <tr>
                        <td colSpan={9}><p className="d-flex align-items-center justify-content-center"> No Data Found</p></td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
              <div className="datatable-pagination">
                Rows per page
                <div className="ms-2 form-control w-auto">
                  <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className="Pagenation-Select">
                    <option value="50">
                      <div className="select-option">
                        50
                      </div>
                    </option>
                    <option value="100">
                      <div className="select-option">
                        100
                      </div>
                    </option>
                    <option value="500">
                      <div className="select-option">
                        500
                      </div>
                    </option>
                    <option value="1000">
                      <div className="select-option">
                        1000
                      </div>
                    </option>
                  </select>
                </div>
                <button onClick={prevPage}
                  disabled={currentPage === 1}
                  className="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface-dark">
                  Previous
                </button>
                <div className="d-flex justify-content-between w-120px align-items-center"> Page
                  {/* {currentPage}  */}
                  <input className="form-control w-50px" value={currentPage} onChange={(e) => setCurrentPage(Number(e.target.value))} />
                  of {totalPages}</div>
                <button onClick={nextPage}
                  disabled={currentPage === totalPages}
                  className="btn btn-link datatable-pagination-button datatable-pagination-right ripple-surface-dark">
                  Next
                </button>
              </div>
            </div>

          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab2"} show={basicActive === "tab2"}>
            <div className="position-relative">
              <div className="refundsExportButton">
                {RoleValue === "admin" && <button type="submit" className="ms-2 btnheight btn btn-primary"
                  onClick={(e) => downloaddata(basicActive === "tab1" ? basicData : basicDataR)}
                >
                  <MDBIcon fas icon="download" className="d-inline me-1" />Export
                </button>}
              </div>
              <div className="refundsTable">
                <MDBDatatable className="refundsDataTable"
                  searchLabel="Search by Name/Order Id/Plan/Addon/Contact" search
                  fixedHeader
                  entries={50}
                  maxHeight={filterr ? "65.5vh" : "75vh"}
                  data={basicDataR}
                />
              </div>
            </div>

          </MDBTabsPane>
          <MDBTabsPane open={basicActive === "tab3"} show={basicActive === "tab3"}>
            <MDBDatatable className="sno"
              fixedHeader
              entries={25}
              maxHeight={filterr ? "65.5vh" : "75vh"}
              data={basicDataP}
            />
          </MDBTabsPane>
        </MDBTabsContent>


        {isOpen && (
          <Popup
            title="Transaction Details"
            content={
              <>
                <TransactionDetails
                  oncancel={closePopup}
                  onAssociate={openAUserPop}
                  id={tId}
                  Rtoken={Rid}
                  transid={transid}
                  filters={filteredobject}
                />
              </>
            }
            handleClose={closePopup}
          />
        )}
        {openAUser && (
          <Popup
            content={
              <>
                <div>
                  <AddUsers id={getToken()} index={1} onsubmit="" submit="" />
                </div>
              </>
            }
            handleClose={closeAddPopup}
          />
        )}
        {deleteTrans && (
          <Popup
            title="Confirm Delete"
            content={
              <>
                <div className="pb-3">
                  <span className="fs-6 pb-3">Are you sure you want to delete this entry?</span>
                </div>
                <MDBModalFooter>
                  <MDBBtn color='secondary' onClick={(e) => setDeleteTrans(null)}>
                    Cancel
                  </MDBBtn>
                  <MDBBtn color="danger" onClick={(e) => transactionDelete(e, deleteTrans)}>Delete</MDBBtn>
                </MDBModalFooter>
              </>
            }
            handleClose={setDeleteTrans}
          />
        )}
      </div>}
      <MDBAlert
        color={Alerttxt.includes("ailed") ? "danger" : (Alerttxt.includes("wait") ? "secondary" : "success")}
        autohide
        position='top-right'
        delay={5000}
        appendToBody
        open={Alerttxt !== ""}
        onClose={() => setAlerttxt("")}
      >
        {Alerttxt}
      </MDBAlert>
      {paymentLink && (
        <Popup
          title="Resend Payment Link"
          ptype="modal-md"
          from="transactions"
          content={
            <>
              <div>
                <span >To resend the payment link, use the same or a different number.</span>
                <div className="d-flex align-items-center py-4">
                  <label>
                    Send payment link to:
                  </label>
                  <div className="ms-3" >
                    <MDBInput value={number} type="number" onChange={(e) => setNumber(e.target.value)} />
                  </div>
                </div>
                <div class="modal-footer pb-0">
                  <button class="ripple ripple-surface btn btn-secondary" role="button" onClick={() => setPaymentLink(null)}>
                    close
                  </button>
                  <button class="ripple ripple-surface btn btn-primary" role="button" onClick={(e) => { setAlerttxt("Please wait."); setPaymentLink(null); resendPayment(e, paymentLink) }} disabled={number === ""}>
                    Send
                  </button>
                </div>
              </div>
            </>
          }
          handleClose={() => setPaymentLink(null)}
        />
      )}
    </>
  );
};

export default Transctions;